<template>
  <button type="button" class="nav-burger" name="navigation button">
    <span
      v-for="element in 3"
      :key="element"
      class="nav-burger__bar"
      :style="burgerButtonStyle"
    />
  </button>
</template>

<script setup lang="ts">
const { burgerButtonStyle } = useNavbarStyle()
</script>

<style lang="scss" scoped>
.nav-burger {
  display: block;
  position: relative;

  &__bar {
    width: 24px;
    height: 2px;
    margin-bottom: 4px;
    display: block;
    background-color: var(--navbar-burger-color);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
