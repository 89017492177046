<script lang="ts" setup>
import type {
  NavigationTab,
  NavigationTabPayload,
} from '@/types'

interface Props {
  currentTab: NavigationTab
  currentTabIndex: number
}

const emit = defineEmits<{
  (e: 'change-tab-state', payload: NavigationTabPayload): void
}>()

const props = defineProps<Props>()


const changeTab = () => {
  emit('change-tab-state', { event: { type: 'OPEN_NEXT' } })
}

const closePanel = () => {
  emit('change-tab-state', {
    event: {
      type: 'RESET',
    },
  })
}
</script>

<template>
  <div
    v-show="currentTab.sections"
    role="tabpanel"
    class='navigation-tab-panel'
    @keydown.esc="closePanel"
    @mouseleave="closePanel"
  >
    <div class="padding-flow">
      <div
        v-for="section in currentTab.sections"
        :key="section.title"
        class='navigation-tab-panel__section'
      >
        <p class="heading--6 navigation-tab-panel__title heading-6">
          {{ $t(`common.navigation.sections_names.${section.title}`) }}
        </p>
        <ul class="flow | navigation-tab-panel__list">
          <NavigationLink
            v-for="(link, index) in section.links"
            :key="index"
            :link="link"
            @close-tab="closePanel"
            @change-tab="changeTab"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation-tab-panel {
  @include shadow-flat-strong;

  --link-padding-top: 8px;
  --link-padding-bottom: 8px;
  --link-padding-inline: 12px;
  --padding-block-start: 24px;
  --padding-block-end: calc(32px - var(--link-padding-bottom));
  --padding-inline: 36px;

  $self: &;

  display: grid;
  overflow: hidden;
  position: absolute;
  z-index: 9998;
  left: var(--offset-x, 0);
  top: calc(100% - 8px);
  transform: translateX(-50%);
  background-color: var(--white);
  border-radius: var(--border-radius-extra-large);
  padding: var(--padding-block-end) 0;
  width: 355px;

  &__list {
    padding: 0 calc(var(--padding-inline) - var(--link-padding-inline));

    --space: 4px;
  }

  &__title {
    padding: 0 var(--padding-inline);
    margin: 0;
    color: var(--cobalt-800);
    margin-bottom: 12px;
  }

  &__section {
    --space: 32px;
  }
}
</style>
