import { useStateMachine } from './useStateMachine'
import type { TabState, TabEventTypes, TabEvents } from '@/types'

export const useTabs = <TTab, TComponentId extends string>(
  tabs: Array<TTab>,
  componentId: TComponentId,
) => {
  const COMPONENT_ATTRS = {
    TAB_ITEM: `${componentId}-target`,
  } as const

  const currentIndex = ref(0)
  const isLastTab = computed(() => currentIndex.value === tabs.length - 1)
  const isFirstTab = computed(() => currentIndex.value === 0)

  const { state, transition } = useStateMachine<
    TabState,
    TabEventTypes,
    TabEvents
  >(
    {
      opened: {
        enter: () => {
          setFocusOnTab(currentIndex.value)
        },
        on: {
          CLOSE: {
            target: 'closed',
          },
          RESET: {
            target: 'closed',
            action: () => (currentIndex.value = 0),
          },
          OPEN_NEXT: {
            target: 'opened',
            action: () => {
              currentIndex.value += 1
            },
            guard: () => !isLastTab.value,
          },
          OPEN_PREVIOUS: {
            target: 'opened',
            action: () => {
              currentIndex.value -= 1
            },
            guard: () => currentIndex.value > 0,
          },
          TOGGLE: {
            target: 'closed',
          },
          OPEN: {
            target: 'opened',
            action: (e) => {
              currentIndex.value = e.index
            },
          },
        },
      },
      closed: {
        on: {
          OPEN: {
            target: 'opened',
            action: (e) => {
              currentIndex.value = e.index
              setFocusOnTab(currentIndex.value)
            },
          },
          TOGGLE: {
            target: 'opened',
          },
        },
      },
    },
    {
      initialState: 'closed',
    },
  )

  const selectedTab = computed(() => tabs[currentIndex.value])
  const isOpen = computed(() => state.value === 'opened')

  const changeTabState = (event: TabEvents) => {
    transition(event)
  }

  const isTabSelected = (index: number) => {
    if (state.value !== 'opened') {
      return false
    }

    return currentIndex.value === index
  }

  const setFocusOnTab = (index: number) => {
    const items = document.querySelectorAll(
      `[data-target=${COMPONENT_ATTRS.TAB_ITEM}]`,
    )
    const nextItem = items[index]?.firstChild as HTMLElement | null

    if (typeof nextItem?.focus !== 'function') {
      return
    }
    nextItem.focus()
  }

  const setTabIndex = (index: number) => {
    if (currentIndex.value === -1 && index === 0) {
      return 0
    }

    return currentIndex.value === index ? 0 : -1
  }

  return {
    selectedTab,
    currentIndex,
    isLastTab,
    isFirstTab,
    state,
    isOpen,
    COMPONENT_ATTRS,
    changeTabState,
    isTabSelected,
    setTabIndex,
  }
}
