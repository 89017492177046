<template>
  <transition @before-leave="beforePromoClosed" @after-leave="onPromoClosed">
    <section
      v-if="isPromoVisible"
      id="floating-promo"
      :class="['floating-promo', `floating-promo--${promobarColor}`]"
    >
      <div class="container container--wide">
        <nuxt-link class="floating-promo__card" :to="link">
          <div class="floating-promo__marquee">
            <div class="floating-promo__content">
              <span
                :class="[
                  'floating-promo__pill',
                  `floating-promo__pill--${promobarColor}`,
                ]"
                >{{ pillText }}
              </span>
              <div class="floating-promo__text">
                {{ text }}
              </div>
              <span class="floating-promo__arrow">→</span>
            </div>
            <div
              class="floating-promo__content floating-promo__content--duplicate"
              aria-hidden="true"
            >
              <span
                :class="[
                  'floating-promo__pill',
                  `floating-promo__pill--${promobarColor}`,
                ]"
                >{{ pillText }}
              </span>
              <div class="floating-promo__text">
                {{ text }}
              </div>
              <span class="floating-promo__arrow">→</span>
            </div>
          </div>
        </nuxt-link>
      </div>
      <button class="floating-promo__close-btn" @click="closePromo"></button>
    </section>
  </transition>
</template>

<script lang="ts" setup>
const store = useMainStore()

const { promo, promobarColor } = storeToRefs(store)

const promoData = computed(() => promo.value?.story.content)
const { cachedUrl } = useStoryblokLink(promoData.value?.link)
const lastPublishedDate = computed(() => promo.value?.story.published_at)
const promoClosedCookie = useCookie('promoClosed', {
  maxAge: 86400,
}) as Ref

const isPromoVisible = ref(
  !promoClosedCookie.value?.isClosed ||
    lastPublishedDate.value !== promoClosedCookie.value?.date,
)

const link = computed(() => {
  return cachedUrl.value
})

const text = computed(() => {
  return promoData.value?.text || ''
})

const pillText = computed(() => {
  return promoData.value?.pill_text || ''
})

const closePromo = () => {
  const promoCookie = {
    isClosed: true,
    date: lastPublishedDate.value,
  }

  isPromoVisible.value = false
  promoClosedCookie.value = JSON.stringify(promoCookie)
}

const beforePromoClosed = (el: HTMLElement) => {
  el.style.height = '69px'
  setTimeout(() => {
    el.style.height = '0'
  })
}

const onPromoClosed = (el: HTMLElement) => {
  el.style.height = ''
}
</script>

<style lang="scss" scoped>
.container {
  padding-left: 0;
  @media (min-width: $breakpoint-md) {
    --container-padding: 54px;
  }

  @media (min-width: $breakpoint-lg) {
    --container-padding: 20px;
    padding-left: var(--container-padding);
  }
}

.floating-promo {
  z-index: 2;
  position: relative;
  overflow: hidden;
  transition: height 0.3s ease;

  &--cobalt {
    background-color: var(--cobalt-200);

    & .floating-promo__card::after {
      background: linear-gradient(
        90deg,
        rgba(214, 214, 250, 0) 0.26%,
        #d6d6fa 51.46%
      );
    }
  }
  &--yellow {
    background-color: var(--yellow-200);

    & .floating-promo__card::after {
      background: linear-gradient(
        90deg,
        rgba(255, 241, 224, 0) -8.65%,
        #fff1e0 56.25%
      );
    }
  }

  &__card {
    @include paragraph-s;
    color: var(--cobalt-800);
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover {
      text-decoration: none;
      .floating-promo__text {
        text-decoration: underline;
      }
      .floating-promo__arrow {
        @media (min-width: $breakpoint-lg) {
          transform: translateX(10px);
        }
      }
    }
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      width: 75px;
      height: 100%;
      position: absolute;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 241, 224, 0) -8.65%,
        #fff1e0 72.42%
      );

      @media (min-width: $breakpoint-md) {
        width: 140px;
      }
      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  &__content {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 100%;
    animation: marquee 15s linear infinite;

    @media (min-width: $breakpoint-lg) {
      animation: none;
      overflow: visible;
      &--duplicate {
        display: none;
      }
    }
  }

  &__pill {
    @include heading-subheading;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 9px;
    margin-right: 16px;

    &--cobalt {
      background-color: var(--cobalt-300);
    }
    &--yellow {
      background-color: var(--yellow-500);
    }
  }

  &__arrow {
    color: var(--cobalt-600);
    margin-left: 8px;
    transition: transform 0.2s ease-out;
  }

  &__close-btn {
    position: absolute;
    z-index: 3;
    top: 28.5px;
    right: 24px;
    cursor: pointer;
    background-image: url('@/assets/icons/fullview-modal-close.svg');
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 48px;
    background-position: center;
  }
  &__marquee {
    display: flex;
    overflow: hidden;
    gap: 64px;

    @media (min-width: $breakpoint-lg) {
      overflow: visible;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 64px));
  }
}
</style>
