<template>
  <div class="nav-lang">
    <div class="nav-lang__button-items">
      <BaseIconSvg
        width="24"
        height="24"
        name="language-planet"
        class="nav-lang__planet"
        :style="langSwitcherStyle"
      />
      <span class="nav-lang__text">
        {{ currentLocale && currentLocale.code }}
      </span>
    </div>
    <ClientOnly>
      <div class="nav-lang__window-wrapper">
        <div class="nav-lang__window">
          <ul class="nav-lang__list">
            <li class="nav-lang__list-item">
              <nuxt-link
                v-if="currentLocale"
                :to="switchLocalePath(currentLocale && currentLocale.code)"
                class="nav-lang__link nav-lang__link--current"
              >
                {{ currentLocale && currentLocale.code }}
              </nuxt-link>
            </li>
            <li
              v-for="lang in availableLocales"
              :key="lang.code"
              class="nav-lang__list-item"
            >
              <nuxt-link
                :aria-disabled="isDisabled(lang.code)"
                :to="
                  !isDisabled(lang.code)
                    ? switchLocalePath(lang.code)
                    : undefined
                "
                :class="[
                  'nav-lang__link',
                  {
                    'nav-lang__link--disabled': isDisabled(lang.code),
                  },
                ]"
              >
                {{ lang.code }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const switchLocalePath = useSwitchLocalePath()
interface Props {
  navbarColor: string
}

const props = defineProps<Props>()
const { availableLocales, currentLocale, isDisabled } = useLanguageSwitcher()

const { langSwitcherStyle } = useNavbarStyle(props.navbarColor)
</script>

<style lang="scss" scoped>
.nav-lang {
  $root: &;

  position: relative;
  column-gap: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-right: 14px;
  cursor: default;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--cobalt-600);
    height: 2px;
    width: 0;
    content: '';
    transition: width 0.2s ease;
  }

  &:hover,
  &:focus,
  &:focus-within {
    #{$root}__planet {
      stroke: var(--cobalt-600);
    }

    #{$root}__text {
      color: var(--cobalt-600);
    }

    #{$root}__window {
      display: block;
    }

    &::after {
      width: 100%;
    }
  }

  &__button-items {
    display: flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
  }

  &__text {
    text-transform: uppercase;
    font-size: var(--font-size-paragraph-xs);
    transition: 0s;
  }

  &__planet {
    width: 24px;
    height: 24px;
    transition: stroke 0.2s ease;
    stroke: var(--lang-switcher-icon-color);
  }

  &__window-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
  }

  &__window {
    @include shadow-large-strong;

    display: none;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    background-color: var(--white);
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__list-item {
    display: block;
    text-wrap: nowrap;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    width: 70px;
    text-align: right;

    &:hover {
      background-color: var(--paper-100);
    }

    &:has(.nav-lang__link--disabled),
    &:has(.nav-lang__link--current) {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__link {
    padding: 8px 12px;
    display: block;
    height: 100%;
    width: 100%;
    color: var(--cobalt-800);
    position: relative;

    &--disabled {
      color: var(--gray-400);
      cursor: not-allowed !important;
      pointer-events: none;
    }

    &--current {
      &::before {
        left: 0;
        top: 45%;
        transform: translateY(-50%);
        position: absolute;
        width: 20px;
        height: 20px;
        content: '';
        background-image: url('@/assets/icons/16-list-benefit-cobalt.svg');
        background-size: cover;
      }
    }

    &:hover {
      text-decoration: none;
      color: var(--cobalt-600);
    }
  }
}
</style>
