<template>
  <NuxtLink class="navigation-logo" :to="localePath('/')">
    <span class="sr-only">Link to home page</span>
    <BaseIconSvg name="logo" class="navigation-logo__icon" />
  </NuxtLink>
</template>
<script lang="ts" setup>
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.navigation-logo {
  color: inherit;
  background: none;

  &__icon {
    color: inherit;
    display: grid;
    place-items: center;
    width: 158px;
    height: 32px;
  }
}
</style>
