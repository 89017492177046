import type { NavigationTab } from '@/types'

const Routes: NavigationTab[] = [
  {
    tabName: 'who_we_help',
    sections: [
      {
        title: 'who_we_help',
        links: [
          {
            to: '/industries/health-plans',
            title: 'insurers',
          },
          {
            to: '/industries/public-health-and-government',
            title: 'government',
          },
          {
            to: '/industries/providers',
            title: 'providers',
          },
          {
            to: '/industries/telemedicine',
            title: 'telemedicine',
          },
        ],
      },
    ],
  },
  {
    tabName: 'solutions',
    sections: [
      {
        title: 'platform',
        links: [
          {
            title: 'platform',
            to: '/platform',
          },
        ],
      },
      {
        title: 'products',
        links: [
          {
            title: 'triage',
            to: '/solutions/triage',
          },
          {
            title: 'intake',
            to: '/solutions/intake',
          },
          {
            title: 'infer-api',
            to: '/solutions/infermedica-api',
          },
        ],
      },
      {
        title: 'solutions',
        links: [
          {
            title: 'self_service',
            to: '/use-cases',
            hash: '#symptom-checker'
          },
          {
            title: 'pre_visit_intake',
            to: '/use-cases',
            hash: '#intake'
          },
          {
            title: 'nurse_triage',
            to: '/use-cases',
            hash: '#nurse-triage'
          },
        ],
      },
    ],
  },
  {
    tabName: 'network',
    sections: [
      {
        column: 'left',
        title: 'partners',
        links: [
          {
            title: 'partners',
            to: '/partner-program',
          },
          {
            title: 'features',
            to: '/partners',
          },
        ],
      },
    ],
  },
  {
    tabName: 'resources',
    sections: [
      {
        title: 'resources',
        links: [
          {
            title: 'publications',
            to: '/research-studies',
          },
          {
            title: 'case_studies',
            to: '/case-studies',
          },
          {
            title: 'patient_stories',
            to: '/patient-stories',
          },
          {
            title: 'resource_hub',
            to: '/reports'
          },
          {
            title: 'blog',
            to: '/blog',
          },
        ],
      },
      {
        column: 'left',
        title: 'technical_resources',
        links: [
          {
            title: 'developer_portal',
            href: 'https://developer.infermedica.com/',
            newWindow: true,
          },
          {
            title: 'help_center',
            href: 'https://help.infermedica.com/',
            newWindow: true,
          },
        ],
      }
    ],
  },
  {
    tabName: 'company',
    sections: [
      {
        title: 'connect_us',
        links: [
          {
            title: 'about',
            to: '/about',
          },
          {
            title: 'careers',
            to: '/careers',
          },
          {
            title: 'events',
            to: '/events',
          },
          {
            title: 'newsroom',
            to: '/newsroom',
          },
          {
            title: 'contact',
            to: '/contact',
          },
        ],
      },
    ],
  },
]

export default Routes
