import { type UnwrapRef, ref } from 'vue'

export type MachineOptions<TState> = {
  initialState: TState
}

export type Event<T extends string> = {
  type: T
}

type MachineTransition<TState extends string, TEventType extends string> = {
  [key in TState]: {
    enter?: () => void
    on: {
      [key in TEventType]?: {
        target: TState
        guard?: (e: Event<TEventType> & { [e: string]: any }) => boolean
        action?: (e: Event<TEventType> & { [e: string]: any }) => void
      }
    }
  }
}

export const useStateMachine = <
  TState extends string,
  TEventTypes extends string,
  TEvent extends Event<TEventTypes>,
>(
  transitions: MachineTransition<TState, TEventTypes>,
  options: MachineOptions<TState>,
) => {
  const state = ref(options.initialState)
  transitions[state.value as TState].enter?.()

  const transition = (event: TEvent) => {
    const newState = transitions[state.value as TState]?.on[event.type]

    if (!newState) {
      return
    }

    if (newState.guard && !newState.guard(event)) {
      return
    }

    newState.action && newState.action(event)
    state.value = newState.target as UnwrapRef<TState>
    transitions[state.value as TState].enter?.()
  }

  return {
    transition,
    state,
  }
}
