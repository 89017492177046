<script lang="ts" setup>
import type { TabEvents, NavigationTab, NavigationTabPayload } from '@/types'

interface Props {
  tab: NavigationTab
  tabName: string
  isLastItem: boolean
  index: number
  isSelected: boolean
  tabIndex: number
}

defineProps<Props>()

const emit = defineEmits<{
  (e: 'change-tab-state', payload: NavigationTabPayload): void
}>()

const handleKeyDown = (event: KeyboardEvent) => {
  switch (event.code) {
    case 'ArrowLeft':
    case 'ArrowRight':
      focusNextElement(event)
      break
    case 'Escape':
      handleTabChange(event, { type: 'RESET' })
      break
    case 'Enter':
      handleTabChange(event, { type: 'TOGGLE' })
      break
    case 'Tab':
      if (event.shiftKey) {
        handleTabChange(event, { type: 'TOGGLE' })
      }
      break
  }
}

const focusNextElement = (event: KeyboardEvent) => {
  const eventType = event.code === 'ArrowRight' ? 'OPEN_NEXT' : 'OPEN_PREVIOUS'

  handleTabChange(event, { type: eventType })
}

const handleTabChange = (e: Event, event: TabEvents) => {
  const offset = calculateOffset(e)

  const payload = {
    offset,
    event,
  }

  emit('change-tab-state', payload)
}

const calculateOffset = (event: Event) => {
  const target = event.target as HTMLElement
  const { width } = target.getBoundingClientRect()
  return `${target.offsetLeft + width / 2}px`
}
</script>

<template>
  <li
    role="presentation"
    :class="[
      'navigation-header-item',
      isSelected && 'navigation-header-item--active',
    ]"
  >
    <button
      role="tab"
      type="button"
      :data-index="index"
      :tabindex="tabIndex"
      :aria-controls="`tab-panel-${index}`"
      class="navigation-header-item__tab-button"
      :aria-expanded="isSelected"
      :aria-selected="isSelected"
      @mouseenter="handleTabChange($event, { type: 'OPEN', index })"
      @focus="handleTabChange($event, { type: 'OPEN', index })"
      @keydown="handleKeyDown"
    >
      <span>{{ $t(`common.navigation.tab_names.${tab.tabName}`) }}</span>
    </button>
  </li>
</template>

<style lang="scss" scoped>
.navigation-header-item {
  $this: &;

  --animation-duration: 200ms;

  display: contents;

  &__tab-button {
    @include paragraph-s;

    position: relative;
    padding: 8px 16px;
    display: flex;
    gap: 6px;
    align-items: center;

    &:focus-visible {
      outline: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--cobalt-600);
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transition: transform var(--animation-duration) ease-in-out;
    }
  }

  &__icon {
    width: 12px;
    transition: transform var(--animation-duration) ease-in-out;
  }

  &--active {
    color: var(--cobalt-600);
    #{$this}__icon {
      transform: rotate(180deg);
    }
    #{$this}__tab-button::after {
      transform: scaleX(1);
    }
  }
}
</style>
