export const useLanguageSwitcher = () => {
  const { locales, locale } = useI18n()
  const mainStore = useMainStore()

  const currentLocale = computed(() =>
    locales.value.find((l) => l.code === locale.value),
  )

  // locales available in i18n (provided in code)
  const availableLocales = computed(() => {
    return locales.value.filter((l) => l.code !== locale.value)
  })

  const { availableLanguages, storyData } = storeToRefs(mainStore)

  // locales available in Storyblok "language" tab options in SbPage (if provided)
  const availableLanguagesAvailableInOptions = computed(() => {
    if (
      storyData.value.content?.available_languages &&
      storyData.value.content?.available_languages.length > 0
    ) {
      return storyData.value.content?.available_languages
    }
    return null
  })

  // locales available in Storyblok page config > slug
  const storyblokLocales = computed(() => {
    const locales = ['en']
    storyData.value.translated_slugs?.forEach(
      (i) => !!i.name && locales.push(i.lang),
    )
    return locales
  })

  // The final list of languages shown in the panel.
  const finalLanguagesThatAreShownInPanel = computed(() => {
    return availableLanguagesAvailableInOptions.value || storyblokLocales.value
  })

  const isDisabled = (code: string) => {
    if (availableLanguages.value) {
      return !availableLanguages.value.includes(code)
    }
    return !storyblokLocales.value.includes(code)
  }

  watchEffect(() => {
    availableLanguages.value = finalLanguagesThatAreShownInPanel.value
  })

  return {
    currentLocale,
    storyblokLocales,
    isDisabled,
    finalLanguagesThatAreShownInPanel,
    availableLocales,
  }
}
